import React from "react"
import { Link } from 'gatsby'
import Logo from "../../../static/images/logo/logo.svg"
import Seo from "../../components/seo"
import BookHeader from "../../components/bookPageHeader"
import BookFrom from "../../components/bookForm"
import ReactBook from "../../../static/images/commonImg/reactbook.svg"
import ReactBookTS from "../../../static/images/commonImg/reactbook-ts.svg"
import ReactBook2 from "../../../static/images/commonImg/reactbook2.svg"
import ReactBook2TS from "../../../static/images/commonImg/reactbook2-ts.svg"
import NodeExpressBook from "../../../static/images/commonImg/nodebook-intro.svg"
import NodeExpressBook2 from "../../../static/images/commonImg/nodebook-advanced.svg"
// import NextBook2a from "../../../static/images/commonImg/nextbook2-1.svg"
// import NextBook2aTS from "../../../static/images/commonImg/nextbook2-1ts.svg"
//import MernBook from "../../../static/images/commonImg/mern-book-1.svg"
import GatsbyBook from "../../../static/images/commonImg/gatsbybook.svg"
import NextBook from "../../../static/images/commonImg/nextbook.svg"
import JamstackBook from  "../../../static/images/commonImg/jamstackbook.svg"
import vuebook from "../../../static/images/commonImg/vuebook.svg"
import NuxtBook from "../../../static/images/commonImg/nuxtbook.svg"
import JSBook from "../../../static/images/commonImg/jsbook.svg"
import ProxyBook from "../../../static/images/commonImg/proxyserver-book.png"
import NextFullAppFolder1 from "../../../static/images/commonImg/nextbook-app-fullstack-1.svg"
import ReactMasterBook from "../../../static/images/commonImg/react-master-book.svg" 
import RegisterNewsLetterBody from "../../components/registerNewsLetter"

const generalData = {
    title: "MERNでつくるフルスタックアプリ（前編・後編）",
    updated: "2024/3/18",
    urlToApp: "https://mern-stack-book.netlify.app",
    urlToCode1: "https://github.com/mod728/mern-stack-book-backend",
    urlToCode2: "https://github.com/mod728/mern-stack-book-frontend",
    reviewLink1: "https://www.amazon.co.jp/review/create-review/?ie=UTF8&channel=glance-detail&asin=B0BJN4H1NF",
    reviewLink2: "https://www.amazon.co.jp/review/create-review/?ie=UTF8&channel=glance-detail&asin=B0BJMM1ZBP"

}

const booksData = [
    {oldPrice: "", newPrice: "", newRelease: "*2024年2月発売", title: "React全部入り：Reactマスター", img: ReactMasterBook, url: "https://monotein.base.shop/p/00001", description: "Reactを『なんとなく』は使える人が、2日で自信を持ってReactを書ける本！<br/><br/>JavaScriptの基礎の基礎、Reactの基本概念、そして応用までの全範囲をカバーした待望の解説書。<br/><br/>React入門に成功したビギナーが、たしかな理解とスキルを身につけて、自走できるようになる一冊。"},
    {oldPrice: "", newPrice: "", newRelease: "", title: "Next.jsでつくるフルスタックサイト　前編・後編", img: NextFullAppFolder1, url: "https://www.amazon.co.jp/dp/B0CNXPW7K2", description: "最新バージョンのNext.jsとAppフォルダを使って、バックエンドとフロントエンドの開発手法を紹介。<br/><br/>アプリ開発の基礎の基礎から、オンラインでの公開方法までの流れをすべて紹介している充実の内容です。"},
    {oldPrice: "", newPrice: "", newRelease: "", title: "はじめてつくるNext.jsサイト", img: NextBook, url: "https://www.amazon.co.jp/dp/B0C7746ZC4", description: "2時間でNext.jsの使い方がわかる入門書。<br/><br/>最新のNext.jsバージョン13と、新たに導入されたAppフォルダの使い方を解説している唯一の日本語書籍（2023年6月時点）"},
    {oldPrice: "", newPrice: "", newRelease: "", title: "はじめてつくるReactアプリ with TypeScript", img: ReactBookTS, url: "https://www.amazon.co.jp/dp/B094Z1R281", description: "「React開発のスタンダードReact + TypeScriptを学んでみたい」<br/><br/>という人向けのReact + TypeScript入門書です。"},
]

const booksDataForSlider = [
    {newRelease: "", title: "", img: ReactBook, url: "https://www.amazon.co.jp/dp/B08XWMVX76", description: ""},
    {newRelease: "", title: "", img: ReactBookTS, url: "https://www.amazon.co.jp/dp/B094Z1R281", description: ""},
    {newRelease: "", title: "", img: ReactBook2, url: "https://www.amazon.co.jp/dp/B09693ZJCV", description: ""},
    {newRelease: "", title: "", img: ReactBook2TS, url: "https://www.amazon.co.jp/dp/B09Q57Q9T1", description: ""},
    {newRelease: "", title: "", img: NodeExpressBook, url: "https://www.amazon.co.jp/dp/B09HQZ1BW8", description: ""},
    {newRelease: "", title: "", img: NodeExpressBook2, url: "https://www.amazon.co.jp/dp/B09X56PSCQ", description: ""},
    {newRelease: "", title: "", img: GatsbyBook, url: "https://www.amazon.co.jp/dp/B0BNQBDHFH", description: ""},
    {newRelease: "", title: "", img: JamstackBook, url: "https://www.amazon.co.jp/dp/B09P3ZVVR2", description: ""},
    {newRelease: "", title: "", img: NuxtBook, url: "https://www.amazon.co.jp/dp/B0B5N8QJ2Y", description: ""},
    {newRelease: "", title: "", img: JSBook, url: "https://www.amazon.co.jp/dp/B0C9C9SVFG", description: ""},
    {newRelease: "", title: "", img: ProxyBook, url: "https://www.amazon.co.jp/dp/B0BBMQS28M", description: ""},
]

const links = [
    {
        chapterNum: "はじめに",
        data: [
            {name: "JSON Formatter", url: "https://chrome.google.com/webstore/detail/json-formatter/bcjindcccaagfpapjjmafapmmgkkhgoa/related"}
        ]
    },
    {
        chapterNum: 1,
        data: [
            {name: "Node.js", url: "https://nodejs.org/en/download"},
            {name: "VS Code", url: "https://code.visualstudio.com/download"},
            {name: "パッケージ情報", url: "https://github.com/mod728/mern-stack-book-backend/blob/master/package.json"},
            {name: "エラー発生時の対処方法", url: "https://monotein.com/blog/how-to-find-and-fix-errors"},
        ]
    },
    {
        chapterNum: 2,
        data: [
            {name: "リクエストの結果番号", url: "https://developer.mozilla.org/ja/docs/Web/HTTP/Status"},
        ]
    },
    {
        chapterNum: 3,
        data: [
            {name: "Thunder Client", url: "https://marketplace.visualstudio.com/items?itemName=rangav.vscode-thunder-client"},
            {name: "MongoDB", url: "https://www.mongodb.com"},
        ]
    },
    {
        chapterNum: 4,
        data: [
            {name: "JSON Web Token", url: "https://jwt.io"},
        ]
    },
    {
        chapterNum: 5,
        data: [
            {name: "Render", url: "https://render.com"},
            {name: "UptimeRobot", url: "https://uptimerobot.com"},
            {name: "第1-5章完了時点のソースコード", url: "https://github.com/mod728/mern-stack-book-backend"},
            {name: "アマゾンレビューURL", url: generalData.reviewLink1},
        ]
    },
    {
        chapterNum: 6,
        data: [
            {name: "1つ目のアイテム", url: "https://github.com/mod728/mern-stack-book-frontend/blob/master/item-data/1st-item.md"},
            {name: "2つ目のアイテム", url: "https://github.com/mod728/mern-stack-book-frontend/blob/master/item-data/2nd-item.md"},
            {name: "3つ目のアイテム", url: "https://github.com/mod728/mern-stack-book-frontend/blob/master/item-data/3rd-item.md"},
            {name: "4つ目のアイテム", url: "https://github.com/mod728/mern-stack-book-frontend/blob/master/item-data/4th-item.md"},
            {name: "5つ目のアイテム", url: "https://github.com/mod728/mern-stack-book-frontend/blob/master/item-data/5th-item.md"},
            {name: "6つ目のアイテム", url: "https://github.com/mod728/mern-stack-book-frontend/blob/master/item-data/6th-item.md"},
            {name: "画像", url: "https://github.com/mod728/mern-stack-book-frontend/tree/master/src/images"},
        ]
    },
    {
        chapterNum: 8,
        data: [
            {name: "CSS", url: "https://github.com/mod728/mern-stack-book-frontend/blob/master/src/App.css"},
        ]
    },
    {
        chapterNum: 9,
        data: [
            {name: "Netlify", url: "https://www.netlify.com"},
            {name: "第6-9章完了時点のソースコード", url: "https://github.com/mod728/mern-stack-book-frontend"},
        ]
    },
    {
        chapterNum: 10,
        data: [
            {name: "Base64変換サイト", url: "https://www.base64-image.de"},
            {name: "Cloudinary", url: "https://cloudinary.com"},
            {name: "アマゾンレビューURL", url: generalData.reviewLink2},
        ]
    },
]

const MernBookLinks = () => (
    <div className="container2">
        <Seo title={generalData.title} noindex={true} />
        <div><Link to="/blog"><img src={Logo} style={{width: "180px", margin: "auto", display: "block", paddingTop: "25px"}}/></Link></div>
        <div style={{textAlign: "center", marginTop: "16px"}}><a rel="noopener noreferrer" target="_blank" href="/blog">フロントエンド情報ブログ</a><span> || </span><a rel="noopener noreferrer" target="_blank" href="/business">著者について</a></div>
        <h3>『{generalData.title}』リンクページ</h3>
        <h4>更新日：<span>{generalData.updated}</span></h4>
        <BookHeader/>
        <ul>
            <h3 style={{backgroundColor: "#525252", display: "inline-block", color: "#fff", padding: "0 40px"}}>全般</h3>
                <li><span style={{color: "red"}}>*MongoDBの設定画面の変更点について（第3章／2024年3月）</span>：<a rel="noopener noreferrer" target="_blank" href="https://monotein-books.netlify.app/mongodb-setup-march-2024">解説ページへ移動する</a></li>
                <li><span style={{color: "red"}}>*create-react-appを学習用に使うことについて + 【React + Vite】の使い方</span>：<a rel="noopener noreferrer" target="_blank" href="https://monotein-books.netlify.app/create-react-app-alternative">解説ページへ移動する</a></li>
                <li><span style={{color: "red"}}>*Cloudinaryの設定画面の変更点について（第10章）</span>：<a rel="noopener noreferrer" target="_blank" href="https://monotein-books.netlify.app/cloudinary-part">解説ページへ移動する</a></li>
                <li><span style={{color: "red"}}>*Json Web Tokenのバージョンについて</span>：本書で使っている「jsonwebtoken」はバージョン8ですが、本書発売以後に新しいバージョン9がリリースされたため、本書の記述通りに進めているとエラーの発生する場合があります。現在、バージョン9に準拠した内容へと本書の書き直しを進めていますが、エラーが発生した場合はターミナルで「npm install jsonwebtoken@^8.5.1」を実行し、「jsonwebtoken」のバージョンを見本アプリのものと準拠させてください。</li>
{/*             <li><span style={{color: "red"}}>*2022年11月より本書第5章で使っているデプロイサービスHerokuのプランがすべて有料化されました。Herokuの代わりに類似の無料サービスRenderを使った新しい版を11月17日に発行していますが、すでに本書を購入あるいはダウンロードされている方は、下記リンクを第5章と読み替えてください</span>：<a rel="noopener noreferrer" target="_blank" href="https://monotein-books.netlify.app/mern-book-chapter5">https://monotein-books.netlify.app/mern-book-chapter5</a></li> */}
            <hr/>
            {generalData.urlToApp && <li>見本アプリ：<a rel="noopener noreferrer" target="_blank" href={generalData.urlToApp}>{generalData.urlToApp}</a></li>}
            <li>ソースコードダウンロード（前編）：<a rel="noopener noreferrer" target="_blank" href={generalData.urlToCode1}>{generalData.urlToCode1}</a></li>
            {generalData.urlToCode2 && 
                        <li>ソースコードダウンロード（後編）：<a rel="noopener noreferrer" target="_blank" href={generalData.urlToCode2}>{generalData.urlToCode2}</a></li>
            }
            <li><span className="amazon-review">Amazonでレビューする（前編）</span>：<a rel="noopener noreferrer" target="_blank" href={generalData.reviewLink1}>{generalData.reviewLink1}</a></li>
            {generalData.reviewLink2 && 
                        <li><span className="amazon-review">Amazonでレビューする（後編）</span>：<a rel="noopener noreferrer" target="_blank" href={generalData.reviewLink2}>{generalData.reviewLink2}</a></li>
            }
        </ul>

        <div className="book-wrapper">
            <div className="gridContainer">
                {booksData.map((x, index) => 
                    <div className="imgTextContainer" key={index}>
                        <a rel="noopener noreferrer" target="_blank" href={x.url}>
                            <img src={x.img} alt="cover"/>
                        </a>
                        <div>
                            {x.newRelease && <p className="new-release">{x.newRelease}</p>}
                            <br/>
                            {x.oldPrice && <p className="new-release-price">リリース記念特価（期間限定）<span>{x.oldPrice}円</span> ➡ {x.newPrice}円</p>}
                            <h3>『{x.title}』</h3>
                            {x.url.includes("monotein.base.shop") ? 
                                <a rel="noopener noreferrer" target="_blank" href={x.url}><p>→もっとくわしく見る</p></a>
                            :  
                                <a rel="noopener noreferrer" target="_blank" href={x.url}><p>Amazonで見る</p></a>
                            }
                            <p dangerouslySetInnerHTML={{ __html: x.description }}/>
                        </div>
                    </div>
                )}
            </div>
        </div>

        <ul>
            {links.map((x, index) => 
                <div key={index}>
                    <h3>{typeof x.chapterNum === "string" ? x.chapterNum : `第${x.chapterNum}章`}</h3>
                    {x.data.map((y, index) => 
                        <li key={index}>{y.name}： <a rel="noopener noreferrer" target="_blank" href={y.url}>{y.url}</a></li>
                    )}
                </div>
            )}
        </ul>
        <div style={{marginTop: "90px" }}></div>
        <RegisterNewsLetterBody 
                fromWhere="MERN-Book"
                backendUrl="https://us-central1-nov182021.cloudfunctions.net/server/news-letter"
                text={<p>React、Next.js、TypeScriptなど最新のウェブ開発のお役立ち情報を、ビギナー向けにかみ砕いて無料配信中。<br/>（*配信はいつでも停止できます）</p>}
        />
    </div>
)

export default MernBookLinks
